import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "details" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { id: "dates" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, [
      _createTextVNode(_toDisplayString(_ctx.activeExperience.position) + " @ ", 1),
      _createElementVNode("a", {
        href: _ctx.activeExperience.website
      }, _toDisplayString(_ctx.activeExperience.employer), 9, _hoisted_2)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.activeExperience.dates), 1),
    _createElementVNode("p", {
      innerHTML: _ctx.activeExperience.description
    }, null, 8, _hoisted_4)
  ]))
}