
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/layout/Footer.vue";
import ExperienceCard from "./ExperienceCard.vue";
import { experiences } from "@/assets/content/experience";

@Options({
  components: {
    ExperienceCard,
    Footer,
  },
})
export default class About extends Vue {
  private experiences = experiences;
  private activeExperience = experiences[0];
}
